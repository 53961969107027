export const MARGINS = {
  section: 20,
  paragraph: 5
}

export const STYLES = {
  default: "default",
  heading: "heading",
  label: "label",
  reducedMargin: "reducedMargin",
  subheading: "subheading",
}

export function pdfFooter(currentPage, pageCount) {
  return {
    style: "footer",
    text: `${currentPage} / ${pageCount}`,
  }
}

export function pdfListItem(numbering, content, style = STYLES.default) {
  let contentColumn = { width: "*", style: style }

  if (!content) {
    contentColumn.text = ""
  } else if (Array.isArray(content) && content.length > 1) {
    contentColumn.ul = content
  } else if (Array.isArray(content)) {
    contentColumn.text = content[0]
  } else if (typeof content === "object") {
    // If the caller provides us with an object, we assume it to be raw pdfmake content and just plop it into our column definition.
    contentColumn = {...contentColumn, ...content}
  } else {
    const paragraphs = typeof content === "string" && content?.split("\n\n")

    if (Array.isArray(paragraphs) && paragraphs.length > 2) {
      const stack = { stack: paragraphs.map(p => ({ text: p, style: STYLES.reducedMargin })) }
      contentColumn = {...contentColumn, ...stack}
    } else {
      contentColumn.text = content
    }
  }

  return {
    columns: [
      { width: 40, text: numbering, style: style },
      contentColumn,
    ]
  }
}

export function pdfContentStack(data, numbering) {
  if (!data.items || !Array.isArray(data.items) || data.items.length === 0) { return }

  const n = numbering
  n.incrementCurrentNumber()

  const intermediate = {
    marginTop: MARGINS.paragraph,
    stack: [
      pdfListItem(n.currentNumber, data.headline, STYLES.subheading),
      pdfListItem("", data.lead),
    ]
  }

  data.items.forEach(item => addPdfContentItemToIntermediate(intermediate, item, n))

  return intermediate
}

export function addPdfContentItemToIntermediate(intermediate, item, numbering) {
  intermediate.stack.push(pdfListItem(numbering?.n() || "", item.label))

  if (Array.isArray(item.files) && item.files.length > 0) {
    intermediate.stack.push(pdfListItem(
      "",
      {
        stack: [
          item.content,
          { ul: item.files, marginTop: MARGINS.paragraph }
        ]
      },
      STYLES.reducedMargin
    ))
  } else {
    intermediate.stack.push(pdfListItem("", item.content, STYLES.reducedMargin))
  }
}
