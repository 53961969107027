class Numbering {
  constructor() {
    this.currentNumber = null
    this.currentSubNumber = null
  }

  incrementCurrentNumber() {
    this.currentNumber = this.currentNumber ? this.currentNumber + 1 : 1
    this.currentSubNumber = null
  }

  n() {
    this.currentSubNumber = this.currentSubNumber ? this.currentSubNumber + 1 : 1

    return `${this.currentNumber}.${this.currentSubNumber}`
  }
}

export default Numbering
