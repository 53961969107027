import { Controller } from "stimulus"
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import "bootstrap"

import Numbering from "../src/numbering"
import { FIELD_TYPES } from "../config/forms"
import { waitForCryptohandler } from "../src/crypto_handler_importer"
import { disableElement, enableElement } from "../src/dom_helper"
import { addPdfContentItemToIntermediate, pdfContentStack, pdfFooter, pdfListItem, MARGINS, STYLES } from "../src/pdfmake"
import { isEmptyRepeatedBlock } from "../src/note_decrypted_content_ui"

const buildDecryptedContentItems = (decryptedContent, repeatedHeaderTranslation) => {
  const items = []

  // We don't just #map/return over decryptedContent so we can flatten the nested/repeated elements.
  Object.keys(decryptedContent).forEach(key => {
    const contentItem = decryptedContent[key]

    switch (contentItem.fieldType) {
      case FIELD_TYPES.repeatable:
        contentItem.data.forEach((content, index) => {
          if (isEmptyRepeatedBlock(content)) { return }

          items.push({
            fieldType: FIELD_TYPES.repeatedHeader,
            key: `${repeatedHeaderTranslation} ${index + 1}`,
            content: buildDecryptedContentItems(content, repeatedHeaderTranslation)
          })
        })
        break;

      default:
        items.push({
          fieldType: contentItem.fieldType,
          key: contentItem.label,
          content: contentItem.data.join("\n")
        })
    }
  })

  return items
}

const buildLegacyDecryptedContentItems = (decryptedContent) => {
  const items = Object.keys(decryptedContent).map(key => {
    const contentItem = decryptedContent[key]

    return {
      fieldType: FIELD_TYPES.legacy,
      key: key,
      content: Array.isArray(contentItem) ? contentItem.join(", ") : contentItem
    }
  })

  return items
}

const buildPdfContent = (data) => {
  const pdfContent = []
  const n = new Numbering()
  let d // Convenience shortcut for the relevant data.xyz object in each section.
  let intermediate

  // Overall header
  if (d = data.header) {
    pdfContent.push([
      { text: d.note, style: STYLES.heading },
      { text: d.account_name, style: STYLES.subheading },
      { text: d.date, style: STYLES.default },
      { text: d.user, style: STYLES.default, marginBottom: MARGINS.section },
    ])
  }

  // Note metadata
  if (d = data.metadata) {
    n.incrementCurrentNumber()

    pdfContent.push({
      marginTop: MARGINS.paragraph,
      stack: [
        pdfListItem(n.currentNumber, d.headline, STYLES.subheading),
        ...d.lines.map(line => pdfListItem(n.n(), line))
      ]
    })
  }

  // Note contents (by now decrypted)
  if (d = data.note) {
    n.incrementCurrentNumber()

    intermediate = {
      marginTop: MARGINS.paragraph,
      stack: [
        pdfListItem(n.currentNumber, d.headline, STYLES.subheading),
        pdfListItem("", d.lead),
      ]
    }

    d.items.forEach(item => {
      switch (item.fieldType) {
        case FIELD_TYPES.repeatedHeader:
          intermediate.stack.push(pdfListItem("", item.key, STYLES.label))
          item.content.forEach(subItem => {
            if (subItem.content) {
              intermediate.stack.push(pdfListItem(n.n(), subItem.key))
              intermediate.stack.push(pdfListItem("", subItem.content, STYLES.reducedMargin))
            }
          })
          break;

        default:
          if (item.content) {
            intermediate.stack.push(pdfListItem(n.n(), item.key))
            intermediate.stack.push(pdfListItem("", item.content, STYLES.reducedMargin))
          }
          break;
      }
    })

    pdfContent.push(intermediate)
  }

  // Internal comments
  const hasAnyComments = Array.isArray(data.comments?.items) &&
    data.comments.items.length > 0 &&
    data.comments.items.some(section => Array.isArray(section?.items) && section.items.length > 0)

  if (hasAnyComments) {
    d = data.comments
    n.incrementCurrentNumber()

    intermediate = {
      marginTop: MARGINS.paragraph,
      stack: [
        pdfListItem(n.currentNumber, d.headline, STYLES.subheading),
        pdfListItem("", d.lead),
      ]
    }

    d.items.forEach(section => {
      intermediate.stack.push(pdfListItem(n.n(), section.key, STYLES.default))
      section.items.forEach(item => addPdfContentItemToIntermediate(intermediate, item))
    })

    pdfContent.push(intermediate)
  }

  // Messages between the whistleblower and the reporting office.
  const hasAnyMessages = Array.isArray(data.messages?.items) && data.messages.items.length > 0
  if (hasAnyMessages) { pdfContent.push(pdfContentStack(data.messages, n)) }

  // Workflow contents.
  const hasAnyWorkflowContents = Array.isArray(data.workflow?.items) && data.workflow.items.length > 0

  if (hasAnyWorkflowContents) {
    d = data.workflow
    n.incrementCurrentNumber()

    intermediate = {
      marginTop: MARGINS.paragraph,
      stack: [
        pdfListItem(n.currentNumber, d.headline, STYLES.subheading),
        pdfListItem("", d.lead),
      ]
    }

    d.items.forEach(item => {
      switch (item.fieldType) {
        case FIELD_TYPES.repeatedHeader:
          intermediate.stack.push(pdfListItem("", item.key, STYLES.label))
          break;

        default:
          intermediate.stack.push(pdfListItem(n.n(), item.key))
          intermediate.stack.push(pdfListItem("", item.content, STYLES.reducedMargin))
          break;
      }
    })

    pdfContent.push(intermediate)
  }

  // Valuations.
  const hasAnyValuations = Array.isArray(data.valuation?.items) && data.valuation.items.length > 0
  if (hasAnyValuations) { pdfContent.push(pdfContentStack(data.valuation, n)) }

  // Event log.
  const hasAnyLogs = Array.isArray(data.log?.items) && data.log.items.length > 0
  if (hasAnyLogs) { pdfContent.push(pdfContentStack(data.log, n)) }

  return pdfContent
}

export default class extends Controller {
  static targets = [
    "dropdownTrigger",
    "featureCheckbox"
  ]

  static values = {
    dataUrl: String,
    error: String,
    repeatedHeaderTranslation: String
  }

  // Events

  async onClick(event) {
    event.preventDefault()
    const container = event.target.closest(".card-body") || event.target

    try {
      disableElement(container)

      this.cryptoHandler = await waitForCryptohandler(this.application)
      const { pdfMake, data, files } = await this.fetchData()
      const pdf = await this.createPdf(pdfMake, data)

      this.saveAsZip(data, pdf, files)

      // Close the dropdown, which we have prevented at the beginning of this call.
      this.hasDropdownTriggerTarget && bootstrap.Dropdown.getInstance(this.dropdownTriggerTarget)?.hide()
    } catch (e) {
      console.error("Export note failed", e)
      window.alert(this.errorValue || "Error")
    } finally {
      enableElement(container)
    }
  }

  // Async imports and file downloads

  async fetchData() {
    const params = new URLSearchParams()

    this.featureCheckboxTargets.forEach(element => {
      if (element.checked) { params.set(`features[${element.value}]`, "true") }
    })

    const noteExportUrl = `${this.dataUrlValue}?${params.toString()}`

    const [data, decryptedData, pdfMake] = await Promise.all([
      fetch(noteExportUrl).then(res => res.json()),
      this.getDecryptedContent(),
      this.importPdfMake(),
    ])

    const files = await Promise.all(data.files.map(file => this.fetchFile(file)))

    this.addDecryptedNoteContent(data, decryptedData)
    await this.decryptMessagesAndComments(data)

    return {
      pdfMake,
      data,
      files
    }
  }

  async fetchFile({ name, url, encrypted }) {
    return {
      name,
      content: await (
        encrypted
          ? this.cryptoHandler.decryptFileFromUrl(url)
          : fetch(url).then(res => res.blob())
      ).catch(e => { console.error("Failed to fetch file", e) })
    }
  }

  async importPdfMake() {
    // pdfMake is not exposed as a module, it is registered as a global variable.
    await import("pdfmake/build/pdfmake")
    return pdfMake
  }

  // Decryption actions

  addDecryptedNoteContent(data, decryptedContent) {
    // Depending on the selected features, the main Note content might not exist.
    if (!data.content?.note) { return }

    if (decryptedContent.encryptedDataFormatVersion == 1) {
      data.content.note.items = buildLegacyDecryptedContentItems(decryptedContent.decryptedJson)
    } else {
      data.content.note.items = buildDecryptedContentItems(decryptedContent.decryptedJson, this.repeatedHeaderTranslationValue)
    }
  }

  async decryptMessagesAndComments(data) {
    const isEncrypted = item => item.isEncrypted
    const encryptedItems = [
      data.content?.comments?.items?.map(section => section.items.filter(isEncrypted)).flat(),
      data.content?.messages?.items?.filter(isEncrypted)
    ].flat().filter(n => n)

    if (!encryptedItems.length) { return }

    return Promise.all(
      encryptedItems.map(item => {
        return this.cryptoHandler.decryptText(item.content).then(decryptedContent => {
          item.content = decryptedContent
          item.isEncrypted = false
        })
      })
    )
  }

  async getDecryptedContent() {
    const decryptionController = this.application.getControllerForElementAndIdentifier(
      document.querySelector(`[data-controller*="note-decryption"]`),
      "note-decryption"
    )

    return {
      encryptedDataFormatVersion: decryptionController.encryptedDataFormatVersion,
      decryptedJson: decryptionController.decryptedJson
    }
  }

  // PDF document/blob creation

  async createPdf(pdfMake, data) {
    const pdf = pdfMake.createPdf(
      {
        ...data.pdfMake,
        content: buildPdfContent(data.content),
        pdfFooter,
      },
      null,
      data.fonts
    )

    return new Promise(resolve => pdf.getBlob(resolve))
  }

  async saveAsZip(data, pdf, files) {
    const zip = new JSZip()
    zip.file(data.meta.filename_pdf, pdf)
    files.forEach(({ name, content }) => zip.file(name, content))

    const blob = await zip.generateAsync({ type: "blob" })
    FileSaver.saveAs(blob, data.meta.filename_zip)
  }
}
